import React, { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import './SearchWithAutosuggest.css';
import { JwtPayload } from 'jwt-decode';
import bingIcon from '../assets/bing-icon.png';
const SearchWithAutosuggest = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);  // 存储userID
  const [questionId, setquestionId] = useState(null);  
  const [currentPage, setCurrentPage] = useState(0);
  const resultsPerPage = 4;//previous 10
  const [focused, setFocused] = useState(false);
  // 函数用于从 URL 中获取查询参数
function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}
//last commit
// // 当文档加载完毕时执行
// document.addEventListener('DOMContentLoaded', function() {
//   // 获取 token
//   const token = getQueryParam('token');
//   if (token) {
//     // 解码 JWT
//     try {
//       const decoded = jwtDecode(token);
//       console.log('Decoded JWT:', decoded);
//       // 你现在可以使用 decoded 对象中的数据，例如显示用户信息，调整UI等
//       // 例如：
//       // if (decoded.username) {
//       //   document.getElementById('usernameDisplay').textContent = `Welcome, ${decoded.username}!`;
//       // }
//     } catch (error) {
//       console.error('Error decoding the token:', error);
//     }
//   } else {
//     console.log('No token found in URL.');
//   }
// });
  // useEffect(() => {
  //   const cookies = document.cookie;
  //   const jwtCookie = cookies.split('; ').find(row => row.startsWith('jwtToken='));
  //   const token = jwtCookie ? jwtCookie.split('=')[1] : null;

  //   if (token) {
  //     try {
  //       const decoded = jwtDecode(token);
  //       console.log('Decoded JWT:', decoded);
  //       setUserId(decoded.userID);  // 提取userID并存储
  //     } catch (error) {
  //       console.error('Error decoding JWT:', error);
  //     }
  //   } else {
  //     console.log("No JWT found in cookies.");
  //   }
  // }, []);
  // const token = sessionStorage.getItem('jwtToken'); // 正确获取 token
  // if (token) {
  //     const decoded = jwtDecode(token); // 解码 JWT
  //     console.log(decoded); // 打印解码后的 JWT 内容
  //     const userId = decoded.userID; // 获取 userID
  //     console.log(userId); // 打印 userID
  // } else {
  //     console.log("No token found in sessionStorage.");
  // }
  // const [questionId, setQuestionId] = useState(null);
//   useEffect(() => {
//   const questionIdFromUrl = getQueryParam('QuestionID');
//   if (questionIdFromUrl) {
//     setQuestionId(questionIdFromUrl);
//   }
// }, []);
  const fetchUserId = (username, password) => {
    fetch('/api/getUserID', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    .then(response => response.json())
    .then(data => {
      if (data.UserID) {
        setUserId(data.UserID);
        console.log('UserID fetched:', data.UserID);
      } else {
        console.error('Failed to fetch UserID:', data.error);
      }
    })
    .catch(error => {
      console.error('Error fetching UserID:', error);
    });
  };
  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        console.log('Decoded JWT:', decoded);
        fetchUserId(decoded.username, decoded.password);
      } catch (error) {
        console.error('Error decoding the token:', error);
      }
    }
  }, []);
  useEffect(() => {
    const questionCode = new URLSearchParams(window.location.search).get('QuestionID');
    if (questionCode) {
      try {
        console.log('QuestionCode:', questionCode);
        setquestionId(questionCode);
      } catch (error) {
        console.error('Error QuestionCode:', error);
      }
    }
  }, []);

  const handleInputChange = async (event) => {
    const newText = event.target.value;
    setQuery(newText);

    if (newText.length > 1) {
      try {
        const suggestEndpoint = `https://api.bing.microsoft.com/v7.0/suggestions?q=${encodeURIComponent(newText)}`;
        const suggestResponse = await fetch(suggestEndpoint, {
          headers: { 'Ocp-Apim-Subscription-Key': 'd6794e3b6b274aef891f123cb57ea63b' } // 替换为您的API密钥
        });
        const suggestData = await suggestResponse.json();
        if (suggestData.suggestionGroups && suggestData.suggestionGroups.length > 0) {
          setSuggestions(suggestData.suggestionGroups[0].searchSuggestions);
        } else {
          setSuggestions([]);
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };
  // const token1 = sessionStorage.getItem('jwtToken');
  // const decoded1 = jwtDecode(token1)
  // const userId = decoded1.userID
  const handleLinkClick = async (url) => {
    try {
      const response = await fetch('/api/save-query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({userID:userId, content:url,actionType:'click', QuestionID:questionId})  // 将 URL 作为 searchQuery 发送
      });
      if (!response.ok) {
        throw new Error('Failed to send URL to database');
      }
      console.log('URL saved successfully');
    } catch (error) {
      console.error('Error saving URL to database:', error);
    }
  };
  

  const fetchSearchResults = async () => {
    if (!query) return;
    setIsLoading(true);
    setError('');
    const offset = currentPage * resultsPerPage;
    try {
      const searchEndpoint = `https://api.bing.microsoft.com/v7.0/search?q=${encodeURIComponent(query)}&count=${resultsPerPage}&offset=${offset}`;
      const response = await fetch(searchEndpoint, {
        headers: { 'Ocp-Apim-Subscription-Key': 'd6794e3b6b274aef891f123cb57ea63b' }
      });
      if (!response.ok) throw new Error('Search failed');
      const data = await response.json();
      setSearchResults(data.webPages ? data.webPages.value : []);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError('Failed to fetch results');
    } finally {
      setIsLoading(false);
    }
  };
  const sendSearchQueryToDatabase = async (searchTerm) => {
    try {
      const response = await fetch('/api/save-query', {  // 确保这个URL与您的无服务器函数的路由匹配
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userID:userId, content:searchTerm,actionType:'search',QuestionID:questionId})
      });
      if (!response.ok) {
        throw new Error('Failed to send query to database');
      }
      const responseData = await response.json();
      console.log('Saved search query:', responseData);
    } catch (error) {
      console.error('Error saving search query to database:', error);
    }
  };
  

  // 当页码改变时，触发新的搜索
  useEffect(() => {
    fetchSearchResults();
  }, [currentPage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(0);
    fetchSearchResults();
    sendSearchQueryToDatabase(query);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

//   return (
//     <div>
//       <h1>Bing Search with Suggestions</h1>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           value={query}
//           onChange={handleInputChange}
//           placeholder="Type to search..."
//         />
//         <button type="submit">Search</button>
//       </form>
//       {suggestions.length > 0 && (
//         <ul>
//           {suggestions.map((suggestion, index) => (
//             <li key={index} onClick={() => setQuery(suggestion.query)}>
//               {suggestion.displayText}
//             </li>
//           ))}
//         </ul>
//       )}
//       {isLoading && <p>Loading results...</p>}
//       {error && <p>{error}</p>}
//       <ul>
//         {searchResults.map((result, index) => (
//           <li key={index}>
//              <a href={result.url} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(result.url)}>{result.name}</a>
//             <p>{result.snippet}</p>
//           </li>
//         ))}
//       </ul>
//       <button onClick={goToPreviousPage} disabled={currentPage === 0}>Previous</button>
//       <button onClick={goToNextPage}>Next</button>
//     </div>
//   );
// };
return (
  // <div className="search-container">
  //   <h1>Bing Search with Suggestions</h1>
  //   <form onSubmit={handleSubmit}>
  //     <input type="text" value={query} onChange={handleInputChange} placeholder="Type to search..." />
  //     <button type="submit">Search</button>
  //   </form>
  //   <div className="suggestions">
  //     {suggestions.map((suggestion, index) => (
  //       <div key={index} className="suggestion-item" onClick={() => setQuery(suggestion.query)}>
  //         {suggestion.displayText}
  //       </div>
  //     ))}
  //   </div>
  //   <div className="results">
  //     {searchResults.map((result, index) => (
  //       <div key={index} className="result-item">
  //         <a href={result.url} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(result.url)}>
  //           {result.name}
  //         </a>
  //         <p>{result.snippet}</p>
  //       </div>
  //     ))}
  //   </div>
  //   {isLoading && <p>Loading...</p>}
  //   {error && <p>{error}</p>}
  // </div>
//   <div className="search-container">
//   <h1>Bing Search with Suggestions</h1>
//   <form onSubmit={handleSubmit}>
//     <input
//       type="text"
//       value={query}
//       onChange={handleInputChange}
//       placeholder="Type to search..."
//     />
//     <button type="submit">Search</button>
//   </form>
//   <div className="suggestions">
//     {suggestions.map((suggestion, index) => (
//       <div key={index} className="suggestion-item" onClick={() => setQuery(suggestion.query)}>
//         {suggestion.displayText}
//       </div>
//     ))}
//   </div>
//   <div className="results">
//     {isLoading && <p>Loading results...</p>}
//     {error && <p>{error}</p>}
//     {searchResults.map((result, index) => (
//       <div key={index} className="result-item">
//         <a href={result.url} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(result.url)}>
//           {result.name}
//         </a>
//         <p>{result.snippet}</p>
//       </div>
//     ))}
//   </div>
//   <button onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))} disabled={currentPage === 0}>Previous</button>
//   <button onClick={() => setCurrentPage(prev => prev + 1)}>Next</button>
// </div>
<div className="search-container">
{/* <h1>Bing Search with Suggestions</h1> */}
<img src={bingIcon} alt="Bing" className="bing-icon" />
<form onSubmit={handleSubmit}>
  <input
    type="text"
    value={query}
    onChange={handleInputChange}
    onFocus={() => setFocused(true)}
    onBlur={() => setTimeout(() => setFocused(false), 100)}
    placeholder="Type to search..."
  />
  <button type="submit">Search</button>
</form>
{focused && suggestions.length > 0 && (
  <div className="suggestions-dropdown">
    {suggestions.map((suggestion, index) => (
      <div
        key={index}
        className="suggestion-item"
        onMouseDown={() => setQuery(suggestion.query)}
      >
        {suggestion.displayText}
      </div>
    ))}
  </div>
)}
<div className="results">
  {searchResults.map((result, index) => (
    <div key={index} className="result-item">
      <a href={result.url} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(result.url)}>
        {result.name}
      </a>
      <p>{result.snippet}</p>
    </div>
  ))}
</div>
{isLoading && <p>Loading...</p>}
{error && <p>{error}</p>}
<button onClick={goToPreviousPage} disabled={currentPage === 0}>Previous</button>
<button onClick={goToNextPage}>Next</button>
</div>
);
};
export default SearchWithAutosuggest;
